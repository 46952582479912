import React, { useState, useEffect } from "react";
import { Table, Form, InputGroup, Spinner, Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import Wrapper from "../Wrapper/Wrapper";
import useAxiosWithAuthErrorHandling from "../../AxiosErrorHandling/AxiosErrorHandling";
import CriminalCheckModal from "./CriminalCheckModal";
import CustomModal from "../../customModal/customModal";
import { toast } from "react-toastify";

const CriminalCheck = () => {
  const [modalShow, setModalShow] = useState(false);
  const [customerDataModal, setCustomerDataModal] = useState(false);
  const [customerIndexing, setCustomerIndexing] = useState(null);

  const [customersData, setCustomersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableRows, setTableRows] = useState(10);
  const [isLoadingHistory, setisLoadingHistory] = useState(true);

  const axios = useAxiosWithAuthErrorHandling();
  const { user } = useSelector((state) => state.userAuth);

  const fetchCustomerData = async () => {
    setisLoadingHistory(true);
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_GET_CRIMINAL_DATA}?userId=${user.id}`,
      });
      if (response.status === 200) {
        console.log("data on success  🙂🙂🙂", response.data.data);

        setCustomersData(response.data.data);
      } else {
        toast.error(response.data.message || "Failed to fetch criminal data.");
      }
    } catch (error) {
      console.error("Error fetching criminal data:", error);
      toast.error("Error fetching criminal data. Please try again.");
    }
    setisLoadingHistory(false);
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_DELETE_CRIMINAL_RECORD}/${id}`,
      });
      if (response.status === 200) {
        toast.success("Record deleted successfully.");
        fetchCustomerData();
      } else {
        toast.error(response.data.message || "Failed to delete record.");
      }
    } catch (error) {
      console.error("Error deleting criminal record:", error);
      toast.error("Error deleting criminal record. Please try again.");
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const filteredData = customersData.filter((customer) => {
    const searchTerm = searchQuery.toLowerCase();
    return (
      customer.name.toLowerCase().includes(searchTerm) ||
      customer.id.toString().includes(searchTerm)
    );
  });

  const indexOfLastItem = currentPage * tableRows;
  const indexOfFirstItem = indexOfLastItem - tableRows;
  const totalRecords = filteredData.length;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowClick = (index) => {
    const originalIndex = indexOfFirstItem + index; // Get the correct index in the `customersData` array
    setCustomerIndexing(originalIndex);
    setCustomerDataModal(true);
  };
  

  const renderPagination = () => {
    const totalPages = Math.ceil(totalRecords / tableRows);
    if (totalPages <= 1) return null;

    let items = [];
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => paginate(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return (
      <Pagination className="justify-content-end mt-3">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => paginate(currentPage - 1)}
        />
        {items}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => paginate(currentPage + 1)}
        />
      </Pagination>
    );
  };

  return (
    <Wrapper>
      <div className="table-page-main-wrapper">
        <div className="table-page-top">
          <h2>Criminal Check</h2>
          <button
            className="button-primary"
            variant="primary"
            onClick={() => setModalShow(true)}
          >
            New Check
          </button>
          <CriminalCheckModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            fetchCustomerData={fetchCustomerData}
          />
        </div>

        <div className="table-wrapper">
          <InputGroup className="mb-3 serach-ontable">
            <InputGroup.Text id="basic-addon1">
              <i className="bi bi-search"></i>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>
          {!isLoadingHistory ? (
            customersData.length ? (
              <>
                <Table className="customerDetails table-hover" responsive="lg">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Count</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((customer, index) => (
                      <tr key={index} onClick={() => handleRowClick(index)}>
                        <td>{customer.id}</td>
                        <td>{customer.name}</td>
                        <td>{customer.count}</td>
                        <td>{customer.count > 0 ? "Failed" : "Passed"}</td>
                        <td>{new Date(customer.createdAt).toLocaleString()}</td>
                        <td>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(customer.id);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </Table>
                {renderPagination()}
              </>
            ) : (
              <div className="data-not-found">No criminal check data</div>
            )
          ) : (
            <Spinner variant="primary" animation="border" />
          )}
        </div>

        <CustomModal
          onHide={() => setCustomerDataModal(false)}
          show={customerDataModal}
          heading={"Criminal Details"}
        >
          {customerIndexing !== null && customersData && (
            <div className="pop-step-tabelcntnt">
              {customersData[customerIndexing]?.crimeData?.candidate?.length > 0 ? (
                customersData[customerIndexing].crimeData.candidate.map((candidate, index) => (
                  <div key={index} className="candidate-info">
                    <h4>Candidate {index + 1}</h4>
                    <Table bordered responsive>
                      <tbody>
                        <tr>
                          <th>Age</th>
                          <td>{candidate.subject?.age || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Date of Birth</th>
                          <td>{candidate.subject?.dob || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Sex</th>
                          <td>{candidate.subject?.sex || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Race</th>
                          <td>{candidate.subject?.race || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Alias</th>
                          <td>{candidate.subject?.alias || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Category</th>
                          <td>{candidate.subject?.category || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>{candidate.subject?.address || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Offenses</th>
                          <td>
                            {candidate.offenses?.offense?.map((offense, idx) => (
                              <div key={idx} className="offense-detail">
                                <p><strong>Statute:</strong> {offense.statute || "N/A"}</p>
                                <p><strong>Description:</strong> {offense.description || "N/A"}</p>
                                <p><strong>Conviction Date:</strong> {offense.convictionDate || "N/A"}</p>
                                <p><strong>Conviction Location:</strong> {offense.convictionLocation || "N/A"}</p>
                              </div>
                            )) || "No offense data available"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))
              ) : (
                <p>No criminal data available for this record.</p>
              )}
            </div>
          )}
        </CustomModal>


      </div>
    </Wrapper>
  );
};

export default CriminalCheck;
