import './App.css';
import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import Login from './Components/Login/Login';
import Dashboard from './Components/Dashboard/Dashboard/Dashboard';
import VerifyEmail from './Components/VerifyEmail/VerifyEmail';
import FogotPassword from './Components/ForgotPassword/ForgotPassword';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import Register from './Components/Register/Register';
import PrivateRoute from './Components/RouteGauard/PrivateRoute';
import AdminRoute from './Components/RouteGauard/AdminRoute';
import GuestRoute from './Components/RouteGauard/GuestRoute';
import SexOffender from './Components/Dashboard/SexOffender/SexOffender';
import SocialMedia from './Components/Dashboard/SocialMedia/SocialMedia';
import PurchaseCredits from './Components/Dashboard/PurchaseCredits/PurchaseCredits';
import DashOutlet from './Components/Dashboard/Outlet/DashOutlet';
import PaymentSuccess from './Components/Dashboard/PaymentSuccess/PaymentSuccess';
import PaymentFailed from './Components/Dashboard/PaymentFailed/PaymentFailed';
import { clearAuth, fetchUserByJWT } from './features/user/userAuth';
// import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import NotFound404 from './Components/NotFound404/NotFound404';
import MyReward from './Components/Dashboard/MyReward';
// import MyPoint from './Components/Dashboard/MyPoints/MyPoint';
import PrivateGuestRoute from './Components/RouteGauard/PrivateGuestRoute';
// import GuestDashboard from './Components/Dashboard/Dashboard/GuestDashboard';
import AdminDashboard from './Components/Dashboard/Dashboard/AdminDashboard';
import GuestReview from './Components/Dashboard/GuestReview';
import RewardsOverView from './Components/Dashboard/RewardsOverView/RewardsOverView';
import TermsAndConditions from './Components/Terms&Conditions/Terms&Conditions';
import RewardAmount from './Components/Dashboard/RewardsOverView/RewardAmount';
import CriminalCheck from './Components/Dashboard/CriminalCheck/CriminalCheck';
import HostReview from './Components/Dashboard/AdminHost/HostReview';
import Subscription from './Components/Subscription/Subscription';
import CustomEmail from './Components/CustomEmail/CustomEmail';
import UpdateEmail from './Components/Dashboard/UpdateEmail/UpdateEmail';
import UpdateSub from './Components/Dashboard/UpdateSub/UpdateSub';
import RewardPaymentSuccess from './Components/Dashboard/PaymentSuccess/RewardPayementSuccess';

function App() {
  const dispatch = useDispatch();
  const { jwtToken, user } = useSelector(state => state.userAuth);
  useEffect(() => {
    if (jwtToken) {
      dispatch(fetchUserByJWT(jwtToken));
    } else {
      dispatch(clearAuth());
    }
  }, []);

  useEffect(() => {
    if (user && user.email) {
      window.Intercom('boot', {
        app_id: 'exuepm35',
        email: user.email,
      });
    } else {
      console.log("Anonymous user");
      window.Intercom('shutdown');
      window.Intercom('boot', {
        app_id: 'exuepm35'
      });
    }
  }, [user]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<GuestRoute Component={Login} />} />
        <Route path="/host" element={<GuestRoute Component={Login} />} />
        <Route path="/guest" element={<GuestRoute Component={Login} />} />
        <Route path="verify-otp" element={<VerifyEmail />} />
        <Route path="reset-password/:token" element={<GuestRoute Component={ResetPassword} />} />
        <Route path="register" element={<GuestRoute Component={Register} />} />
        <Route path="register/guest" element={<GuestRoute Component={Register} />} />
        <Route path="register/host" element={<GuestRoute Component={Register} />} />
        <Route path="forgot-password" element={<GuestRoute Component={FogotPassword} />} />
        <Route path="terms&conditions" element={<GuestRoute Component={TermsAndConditions} />} />
        <Route path="customemail" element={<GuestRoute Component={CustomEmail} />} />
        <Route path="subscription" element={<GuestRoute Component={Subscription} />} />

        <Route path="guest-dashboard" element={<PrivateGuestRoute Component={DashOutlet} />}>
          {/* <Route index element={<GuestDashboard/>} /> */}
          {/* <Route path="my-point" element={<MyPoint />} /> */}
          <Route index element={<MyReward />} />
        </Route>

        <Route path="dashboard" element={<PrivateRoute Component={DashOutlet} />}>
          <Route index element={<Dashboard />} />
          <Route path="guest-review" element={<GuestReview />} />
          <Route path="rewards-overview" element={<RewardsOverView />} />
          <Route path="reward-amount/:rewardId" element={<RewardAmount />} />
          <Route path="sex-offender" element={<SexOffender />} />
          <Route path="social-media" element={<SocialMedia />} />
          <Route path="purchase-credits" element={<PurchaseCredits />} />
          <Route path="payment-successful" element={<PaymentSuccess />} />
          <Route path="Reward-payment-successful" element={<RewardPaymentSuccess />} />
          <Route path="payment-failed" element={<PaymentFailed />} />
          <Route path="update-email" element={<UpdateEmail />} />
          <Route path="update-subscription" element={<UpdateSub />} />
          <Route path="criminal-check" element={<CriminalCheck />} />
        </Route>

        <Route path="admin-dashboard" element={<AdminRoute Component={DashOutlet} />}>
          <Route index element={<AdminDashboard />} />
          <Route path="host-review" element={<HostReview />} />
        </Route>
        <Route path="*" element={<NotFound404 />} />
      </>
    )
  );
  return (
    <RouterProvider router={router} />
  );
}

export default App;
