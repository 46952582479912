import React, { useEffect, useState } from "react"; // Correct import for useEffect and useState
import { useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import axios from "axios";
import "./index.css";
import Wrapper from "../Wrapper/Wrapper";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="model-custom-dsg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Sex Offender Check
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="pop-up-step-one">
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Input Name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" placeholder="Input Address" />
            </Form.Group>

            <Button variant="primary">Submit</Button>
          </div>

          {/* <div className='pop-up-step pop-up-step-two'>

                    <Image src='./dollar-icon-pay.svg'/>
                    <div className='pop-step-cntnt'>
                        <h5>Payment Confirmation</h5>
                        <p>This is going to be 1 credit ($1.00).</p> 
                        <p>Please confirm to continue</p>
                    </div>
                    <Button variant="primary">
                        Pay Now
                    </Button>
                </div> */}

          {/* <div className='pop-up-step pop-up-step-three'>

                    <Image src='./checklist.svg'/>
                    <div className='pop-step-cntnt'>
                        <h5>Successful payment</h5>
                        <p>You have successfully paid, click OK to continue</p>
                    </div>
                    <Button variant="primary">
                        OK
                    </Button>
                </div> */}

          {/* <div className='pop-up-step pop-up-step-three'>

                <Image className="checklist-img-dul" src='./checklist-sm.svg'/>
                <div className='pop-step-tabelcntnt'>
                  <h6 className='tittle'>Result of Sex Offender Check</h6>
                    <Table responsive="lg" className='popup-table'>
                        <tr>
                            <th>Name</th>
                            <td>Andreas</td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td>Street 12 USA</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>Passed ✅</td>
                        </tr>
                    </Table>
                </div>
                </div> */}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
const MyReward = () => {
  const user = useSelector((state) => state.userAuth.user);
  const { jwtToken } = useSelector((state) => state.userAuth);
console.log("😂😂😂😂😂 token in my reward screen" ,jwtToken);

  const email = user.email;

  const [rewards, setRewards] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRewards, setFilteredRewards] = useState([]);

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_GET_All_REWARDS}?email=${email}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        setRewards(response.data.rewards);
        setFilteredRewards(response.data.rewards);
      } catch (error) {
        toast.error("Failed to fetch rewards");
      }
    };

    fetchRewards();
  }, [email, jwtToken]);

  useEffect(() => {
    const results = rewards.filter((reward) =>
      reward.status.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredRewards(results);
  }, [searchQuery, rewards]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Wrapper>
      <div className="table-page-main-wrapper">
        <div className="row justify-content-between align-items-center mb-4">
        <div className="col-auto">
        <div className="table-page-top">
          <h2>My Rewards</h2>

          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
        </div>
        <div className="col-auto">
          <div className="credit-balance">
            <p>
              Unique ID: <strong>{user?.unique_code}</strong>
            </p>
          </div>
        </div>
      </div>
        {rewards && (
          <div className="alert alert-warning text-center" role="alert">
            Please check your Email to claim rewards.
          </div>
        )}
        <div className="table-wrapper mt-2">
          <InputGroup className="mb-3 serach-ontable">
            <InputGroup.Text id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search by Status"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputGroup>
          {/* <div className="tabs">
              <button className="tab active">Available</button>
              <button className="tab">Redeemed</button>
            </div> */}
          <Table responsive="lg">
            <thead>
              <tr>
                <th>ID</th>
                <th>Amount</th>
                <th>Message</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredRewards.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.amount}</td>
                  <td>{item.message}</td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Wrapper>
  );
};

export default MyReward;
