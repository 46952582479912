import React from 'react'
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
const StaffSidebar = () => {
  return (

          <Navbar expand="lg" className="flex-column">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="flex-column">
                  <Nav.Item>
                    <NavLink end to="/dashboard">
                        <Image src={ process.env.PUBLIC_URL + "/nav-ico1.svg"} fluid={true.toString()}/> Dashboard
                    </NavLink>
                  </Nav.Item>

                  {/* <Nav.Item>
                    <NavLink end to="/dashboard/guest-review">
                        <Image src={ process.env.PUBLIC_URL + "/nav-ico2.svg"} fluid={true.toString()}/> Guest Review 
                    </NavLink>
                  </Nav.Item> */}

                  <Nav.Item>
                    <NavLink end to="/dashboard/rewards-overview">
                        <Image src={ process.env.PUBLIC_URL + "/nav-ico3.svg"} fluid={true.toString()}/> Rewards Overview
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item>
                    <NavLink end to="/dashboard/sex-offender" >
                    <Image src={ process.env.PUBLIC_URL + "/nav-ico4.svg"} fluid={true.toString()}/> Sex Offender Check 
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item>
                    <NavLink end to="/dashboard/criminal-check">
                    <Image src={ process.env.PUBLIC_URL + "/nav-ico5.svg"} fluid={true.toString()}/> Criminal Check
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item>
                    <NavLink end to="/dashboard/purchase-credits" >
                        <Image src={ process.env.PUBLIC_URL + "/nav-ico6.svg"} fluid={true.toString()}/> Purchase Credits
                    </NavLink>
                  </Nav.Item>

                </Nav>
              </Navbar.Collapse>
            </Navbar>
 
  )
}

export default StaffSidebar
