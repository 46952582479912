import React, { useEffect, useState, useContext } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Wrapper from "../Wrapper/Wrapper";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { setAuth } from "../../../features/user/userAuth";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../GuestReview/index.css";
import DashboardContext from "../../../context/Dashboard/DashboardContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const HostReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSetUser } = useContext(DashboardContext);

  const [isSpinnerActive, setisSpinnerActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [host, setHost] = useState([]);
  const [filteredhosts, setFilteredhosts] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedHost, setSelectedHost] = useState(null);
  const [emailDetails, setEmailDetails] = useState(null); 

  const { jwtToken } = useSelector((state) => state.userAuth);
  const user = useSelector((state) => state.userAuth.user);
  const adminId = user.id;

  useEffect(() => {
    const fetchhosts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_GET_All_HOST,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        setHost(response.data.users || []);
        setFilteredhosts(response.data.users || []);
      } catch (error) {
        toast.error("Failed to fetch hosts");
      } finally {
        setLoading(false);
      }
    };
    fetchhosts();
  }, [jwtToken, adminId]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = host.filter((host) =>
      host.email.toLowerCase().includes(query)
    );
    setFilteredhosts(filtered);
  };

  const handleRowClick = (host) => {
    setSelectedHost(host);
    fetchEmailDetails(host.email); 
    setModalShow(true);
  };

  const fetchEmailDetails = async (email) => {
    try {
      setLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_API_GET_EMAIL_DETAIL,
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
          params: { email: email }, 
        }
      );
      if (response.data) {
        setEmailDetails(response.data);
        console.log(response.data);

      } else {
        toast.error("Email not found");
      }
    } catch (error) {
      toast.error("Failed to fetch email details");
    } finally {
      setLoading(false);
    }
  };

  const handImpersonate = async (userEmail) => {
    try {
      setisSpinnerActive(true);

      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_API_IMPERSONATE,
        data: {
          email: userEmail,
          rememberMe: false,
          roleId: 1,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const authData = {
          jwtToken: response.data.token,
          user: response.data.user,
          rememberMe: false,
          isOtpVerified: response.data.isOtpVerified,
          roleId: 1,
        };

        await dispatch(
          setAuth({
            ...authData,
            adminId,
          })
        );

        if (response.data.isOtpVerified === false) {
          navigate("/verify-otp");
        } else {
          handleSetUser(response.data.user);
          navigate("/dashboard");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to impersonate");
    } finally {
      setisSpinnerActive(false);
    }
  };

  return (
    <Wrapper>
      <div className="table-page-main-wrapper">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Host Review Page</h2>
        </div>
        <div className="table-wrapper">
          <InputGroup className="mb-3 serach-ontable">
            <InputGroup.Text id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search by Email"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>

          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Table responsive="lg">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Impersonate</th>
                </tr>
              </thead>
              {filteredhosts.length > 0 ? (
                <tbody>
                  {filteredhosts.map((host) => (
                    <tr
                      key={host.id}
                      onClick={() => handleRowClick(host)} 
                      style={{
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f0f0f0")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      <td>{host.id}</td>
                      <td>{host.email}</td>
                      <td>{host.firstName}</td>
                      <td>{host.lastName}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handImpersonate(host.email);
                          }}
                          disabled={isSpinnerActive}
                        >
                          {isSpinnerActive ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Impersonating...
                            </>
                          ) : (
                            "Impersonate"
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="5">No hosts data available</td>
                  </tr>
                </tbody>
              )}
            </Table>
          )}
        </div>

        {selectedHost && (
         <Modal
         show={modalShow}
         onHide={() => setModalShow(false)}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
         centered
       >
         <Modal.Header closeButton className="bg-primary text-white">
           <Modal.Title id="contained-modal-title-vcenter" className="fw-bold">
             Host Details
           </Modal.Title>
         </Modal.Header>
         <Modal.Body className="p-4">
           <Table className="table-borderless">
             <tbody>
               <tr>
                 <th className="text-secondary">ID</th>
                 <td className="fw-semibold">{selectedHost.id}</td>
               </tr>
               <tr>
                 <th className="text-secondary">Email</th>
                 <td className="fw-semibold">{selectedHost.email}</td>
               </tr>
               <tr>
                 <th className="text-secondary">First Name</th>
                 <td className="fw-semibold">{selectedHost.firstName}</td>
               </tr>
               <tr>
                 <th className="text-secondary">Last Name</th>
                 <td className="fw-semibold">{selectedHost.lastName}</td>
               </tr>
       
               {emailDetails && (
                 <>
                   <tr>
                     <th className="text-secondary">Logo</th>
                     <td>
                       <img
                         src={emailDetails.logo_url}
                         alt="Logo"
                         className="img-fluid"
                         style={{ maxHeight: "50px" }}
                       />
                     </td>
                   </tr>
                   <tr>
                     <th className="text-secondary">Email</th>
                     <td className="fw-semibold">{emailDetails.email}</td>
                   </tr>
                   <tr>
                     <th className="text-secondary">Message</th>
                     <td className="fw-semibold">{emailDetails.message}</td>
                   </tr>
                   <tr>
                     <th className="text-secondary">Subject</th>
                     <td className="fw-semibold">{emailDetails.subject}</td>
                   </tr>
                 </>
               )}
             </tbody>
           </Table>
         </Modal.Body>
         <Modal.Footer>
           <Button variant="secondary" onClick={() => setModalShow(false)}>
             Close
           </Button>
         </Modal.Footer>
       </Modal>
       
        )}
      </div>
    </Wrapper>
  );
};

export default HostReview;
