import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import DashboardContext from "../../context/Dashboard/DashboardContext";
import LazyLoadImage from "../LazyLoad/LazyLoad";
import Tab from "react-bootstrap/Tab";
import { useLoadScript } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import { setAuth } from "../../features/user/userAuth";
import { useDispatch } from "react-redux";
import "./Register.css";

const libraries = ["places"];

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSetUser } = useContext(DashboardContext);
  const [key, setKey] = useState("guest");
  const location = useLocation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm_password: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    accept: false,
  });

  const [isSpinnerActive, setisSpinnerActive] = useState(false);
  const addressRef = useRef(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value;
    if (name === "firstName" || name === "lastName") {
      trimmedValue = value.trim();
    }
    setFormData({ ...formData, [name.trim()]: trimmedValue });
  };
  useEffect(() => {
    if (location.state?.userType) {
      const userType = location.state.userType;
      setKey(userType);
    }
  }, [location.state?.userType]);
  useEffect(() => {
    if (isLoaded && key === "guest") {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressRef.current,
        {
          types: ["address"],
          componentRestrictions: { country: "us" },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setFormData({
          ...formData,
          address: place.formatted_address,
        });
      });
    }
  }, [isLoaded, key, formData]);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setisSpinnerActive(true);
      const {
        firstName,
        lastName,
        email,
        password,
        confirm_password,
        mobile,
        address,
        accept,
      } = formData;

      if (
        !firstName ||
        !lastName ||
        !email ||
        !password ||
        !confirm_password ||
        !mobile
      ) {
        toast.error("All fields are required");
        setisSpinnerActive(false);
        return;
      }

      if (!accept) {
        toast.error("You must Accept the Terms & Conditions");
        setisSpinnerActive(false);
        return;
      }
      if (key === "guest" && !address) {
        toast.error("All fields are required");
        setisSpinnerActive(false);
        return;
      }

      if (confirm_password !== password) {
        toast.error("Passwords do not match");
        setisSpinnerActive(false);
        return;
      }

      if (!/^\d+$/.test(mobile)) {
        toast.error("Invalid Mobile Number");
        setisSpinnerActive(false);
        return;
      }

      let newFormData = {
        ...formData,
        guest: key === "guest",
      };

      const response = await axios.post(
        process.env.REACT_APP_API_REGISTER,
        newFormData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 201) {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirm_password: "",
          mobile: "",
          address: "",
          state: "",
          city: "",
        });
        toast.success(response.data.message);

        // Set the user in the global context
        handleSetUser(response.data.user);
        if (key === 'host') {
          navigate("/subscription", { state: { userType: key } });
        } else {        
          const authData = {
            jwtToken: response.data.token,
            user: response.data.user,
            isOtpVerified: true,
            roleId: response.data.user.roleId,
          };
          dispatch(setAuth(authData));
          navigate("/guest-dashboard", { state: { userType: key } });
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    }
    setisSpinnerActive(false);
  };
  console.log("key in signup page ", key);
  return (
    <Container fluid className="register-screen">
      <LazyLoadImage
        effect="blur"
        className="logo"
        src={process.env.PUBLIC_URL + "/logo.png"}
        fluid={true.toString()}
      />

      <Tabs
        activeKey={key}
        id="uncontrolled-tab-example"
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="guest" title="Guest Registration">
          <Row>
            <Col md={6} className="animation-wrapper">
              <div className="register-left">
                <div className="sun">
                  <LazyLoadImage
                    effect="blur"
                    src={process.env.PUBLIC_URL + "/sun.png"}
                    fluid={true.toString()}
                  />
                </div>
                <div className="building">
                  <LazyLoadImage
                    effect="blur"
                    src={process.env.PUBLIC_URL + "/login-home.png"}
                    fluid={true.toString()}
                  />
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className="register-right">
                <div className="sign-up-form">
                  <h2>Sign Up</h2>
                  <p>Please fill your detail to create your account.</p>

                  <div className="sign-in-form-wrp">
                    <Form onSubmit={handleRegister}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridFirstName">
                          First Name
                        </Form.Label>
                        <Form.Control
                          id="formGridFirstName"
                          type="text"
                          placeholder="Enter First Name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridLastName">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          id="formGridLastName"
                          type="text"
                          placeholder="Enter Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridEmail">Email</Form.Label>
                        <Form.Control
                          id="formGridEmail"
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridMobile">Mobile</Form.Label>
                        <Form.Control
                          id="formGridMobile"
                          type="number"
                          name="mobile"
                          placeholder="Enter your Mobile Number"
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridAddress">
                          Address
                        </Form.Label>
                        <Form.Control
                          id="address"
                          type="text"
                          placeholder="Enter Address"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          ref={addressRef}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridPassword">
                          Password
                        </Form.Label>
                        <Form.Control
                          id="formGridPassword"
                          type="password"
                          placeholder="Enter Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />

                        <Form.Text className="form-text text-muted from-input-description">
                          Password must include at least one uppercase letter,
                          one lowercase letter, one number, and one special
                          character.
                        </Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridConfirmPassword">
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          id="formGridConfirmPassword"
                          type="password"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          value={formData.confirm_password}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 form-check">
                        <Form.Check
                          type="checkbox"
                          label="I accept the Terms & Conditions"
                          name="accept"
                          checked={formData.accept}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              accept: e.target.checked,
                            })
                          }
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSpinnerActive}
                      >
                        {isSpinnerActive ? (
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "Register as Guest"
                        )}
                      </Button>
                    </Form>
                    <p className="form-text text-muted sign-in-text">
                      Already have an account?&nbsp;
                      <Link to="/" state={{ userType: "guest" }} className="text-decoration-none">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="host" title="Host Registration">
          <Row>
            <Col md={6} className="animation-wrapper">
              <div className="register-left">
                <div className="sun">
                  <LazyLoadImage
                    effect="blur"
                    src={process.env.PUBLIC_URL + "/sun.png"}
                    fluid={true.toString()}
                  />
                </div>
                <div className="building">
                  <LazyLoadImage
                    effect="blur"
                    src={process.env.PUBLIC_URL + "/login-home.png"}
                    fluid={true.toString()}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="register-right">
                <div className="sign-up-form">
                  <h2>Sign Up</h2>
                  <p>Please fill your detail to create your account.</p>
                  <div className="sign-in-form-wrp">
                    <Form onSubmit={handleRegister}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridFirstName">
                          First Name
                        </Form.Label>
                        <Form.Control
                          id="formGridFirstName"
                          type="text"
                          placeholder="Enter First Name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridLastName">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          id="formGridLastName"
                          type="text"
                          placeholder="Enter Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridEmail">Email</Form.Label>
                        <Form.Control
                          id="formGridEmail"
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridMobile">Mobile</Form.Label>
                        <Form.Control
                          id="formGridMobile"
                          type="number"
                          name="mobile"
                          placeholder="Enter your Mobile Number"
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridPassword">
                          Password
                        </Form.Label>
                        <Form.Control
                          id="formGridPassword"
                          type="password"
                          placeholder="Enter Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <Form.Text className="form-text text-muted from-input-description">
                          Password must include at least one uppercase letter,
                          one lowercase letter, one number, and one special
                          character.
                        </Form.Text>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="formGridConfirmPassword">
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          id="formGridConfirmPassword"
                          type="password"
                          placeholder="Enter Password Again"
                          name="confirm_password"
                          value={formData.confirm_password}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <div className="forgot-paswrd-div-n-remember">
                        <Form.Group className="mb-0" id="formGridCheckbox">
                          <Form.Check
                            type="checkbox"
                            name="accept"
                            checked={formData.accept}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                accept: e.target.checked,
                              })
                            }
                            label="Accept"
                          />
                        </Form.Group>
                        <p className="forgot-password">
                          <Link to="/terms&conditions">Terms & Conditions</Link>
                        </p>
                      </div>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSpinnerActive}
                      >
                        {isSpinnerActive ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Register as Host"
                        )}
                      </Button>
                    </Form>
                    <p className="form-text text-muted sign-in-text">
                      Already have an account?&nbsp;
                      <Link to="/" state={{ userType: "host" }} className="text-decoration-none">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Register;
